const colors = {
  custom: {
    '50': '#74CFD1',

    '500': '#74CFD1',
    '600': '#38a8aa',
  },
}

export default colors
