// import { useScreenSize } from 'general'

function useHasSideNavigation() {
  /*const screenSize = useScreenSize()
  return screenSize >= ScreenSize.Large*/

  return false
}

export default useHasSideNavigation
