const Alert = {
  variants: {
    subtle: {
      container: {
        bg: 'gray.100',
        borderRadius: 6,
      },
    },
  },
}

export default Alert
